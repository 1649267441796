@import "_btns.css";
@import "_cookies.css";
@import "_dropdown.css";

/* container */
.container {
    margin: auto;
    width: 90%;
}
.container-narrow {
    margin: auto;
    width: 70%;
}

/* svg */
svg {
    stroke: var(--stroke);
    fill: var(--fill);
    stroke-width: var(--stroke-width);
    transition: .2s ease-in-out;
}


/* titles */
.h1 {
    font-size: var(--font-s);
    margin-bottom: calc(var(--gap-padding) / 4);
}
.h2 {
    font-size: var(--font-l);
    font-weight: 400;
    margin-bottom: var(--gap-padding);
    line-height: 1.2;
}
.h3 {
    font-size: var(--font-m);
    font-weight: 400;
    margin-bottom: calc(var(--gap-padding) / 4);
}
.h4 {
    font-weight: 400;
    margin-bottom: calc(var(--gap-padding) / 4);
}

.title{
    display: flex;
    flex-direction: column;
    align-items: center;
}


/* breadcrumbs */
.breadcrumb {
    font-size: var(--font-xs);
    letter-spacing: .02rem;
    margin-bottom: var(--min-gutter);
}
.breadcrumb ol {
    display: flex;
    justify-content: center;
    align-items: center;
}
.breadcrumb li + li:before {
    content: '/';
    margin: 0 5px;
    transition: .3s ease-in-out;
}
.breadcrumb li a:hover {
    opacity: 0.5;
}


/* paragraphe */
.paragraphe {
    line-height: 1.4;
    font-size: var(--font-s);
}

.paragraphe + * {
    margin-top: calc(22px + 1.5%);
}

.paragraphe > * + * {
    margin-top: calc(10px + 1%);
}

.paragraphe ol,
.paragraphe ul {
    padding-left: 20px;
}

.paragraphe li {
    padding-left: 10px;
    list-style: disc;
}

.paragraphe li + li {
    margin-top: 5px
}

.paragraphe ul ul {
    margin: 5px 0 10px;
}

.paragraphe a {
    font-weight: bold;
    transition: .2s;
    overflow-wrap: anywhere;
}

.paragraphe .h2,
.paragraphe .h3,
.paragraphe .h4 {
    margin-bottom: 0;
}


/* pagination */
.pagination ol {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.pagination ol {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: calc(var(--gap-padding) - 15px) -15px -15px;
}
.pagination ol li {
    cursor: pointer;
    margin: 15px;
}


/* badge */
.badge {
    white-space: nowrap;
    display: inline-block;
    background: var(--secondary);
    color: var(--light);
    text-transform: uppercase;
    letter-spacing: 0;
    font-size: 9px;
    font-weight: 700;
    padding: 4px 8px 2px 9px;
    border-radius: 4px;
}


/* alert */
.alert {
    background: var(--dark);
    border-radius: 1px;
    color: var(--light);
    font-size: 11px;
    font-weight: 400;
    line-height: 1;
    padding: 20px;
}
.alert.alert-danger, .btn.danger, .badge.badge-danger, .badge.danger {
    background: #ff5353;
}
.alert.alert-warning, .btn.warning, .badge.badge-warning, .badge.warning {
    background: #bd6e28;
}
.alert.alert-success, .btn.success, .badge.badge-success, .badge.success {
    background: #8dc111;
}
.alert.alert-info, .btn.info, .badge.badge-info, .badge.info {
    background: var(--secondary);
}
.alert.hide {
    transition: opacity .3s;
}
.alert [data-close-alert] {
    position: absolute;
    top: 2px;
    right: 4px;
    font-size: 10px;
}
.alert.no-close [data-close-alert] {
    display: none;
}
.alert.none {
    display: none;
}


/* data-mail */
[data-mail][data-domain]::after {
    content: attr(data-mail) "@" attr(data-domain);
    cursor: pointer;
}


/* push */
[data-push] {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 9999999;
    padding: calc(10px + .5vw);
    pointer-events: none;
}
[data-push] > div {
    margin-top: 10px;
    pointer-events: auto;
    padding: calc(20px + 3 * (100vw - 320px) / 1080);
    font-size: 11px;
    width: 300px;
    max-width: calc(100vw - 40px);
    display: flex;
    background: #fff;
    box-shadow: 0 15px 20px rgba(0, 0, 0, .1);
    border-radius: 3px;
    user-select: none;
    -moz-user-select: none;
}
[data-push] i {
    font-size: 25px;
    margin-right: 15px;
}
[data-push] .success i:before {
    content: '\f058';
    color: #8dc111;
}
[data-push] .error i:before {
    content: '\f057';
    color: #ff5353;
}
[data-push] strong {
    font-weight: bold;
    display: block;
    font-size: 12px;
    margin-bottom: 5px;
}
[data-push] .success strong:before {
    content: 'Succès';
    color: #8dc111;
}
[data-push] .error strong:before {
    content: 'Erreur';
    color: #ff5353;
}
[data-push] button {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 12px;
    opacity: .3;
}
[data-push] > div:hover button {
    opacity: .5;
}
[data-push] button:hover {
    opacity: 1;
}


/* captcha */
.grecaptcha-badge {
    display: none;
}
.captcha-mention {
    font-size: calc(9px + 2 * (100vw - 320px) / 1080);
    line-height: 1.2;
}
.captcha-mention > span {
    display: block;
}
.captcha-mention > a {
    opacity: .6;
    transition: .3s;
    font-size: 90%;
}
.captcha-mention > a:hover {
    opacity: 1;
}


/* share */
.share {
    margin-top: var(--section-padding);
    padding-top: var(--gutter);
    border-top: 1px solid rgba(var(--rgb-light), .1);
}
.share-wrapper {
    display: flex;
    justify-content: space-between;

}
.share-left ul {
    display: flex;
}
.share-left a {
    display: block;
    --dim: 30px;
    height: var(--dim);
    width: var(--dim);
    --fill: var(--primary);
    --stroke: none;
}
.share-left a:hover {
    --fill: var(--secondary);
}


/* bloc team solo  */
.bloc_img_team a picture img{
    max-width: 22em;
    aspect-ratio: 3/4;
    object-fit: cover;
    filter: grayscale(1);
}
.bloc_img_team h3,
.bloc_img_team h4{
    font-family: var(--ff2);
    text-transform: uppercase;
}
.bloc_img_team h3{
    margin-top: var(--min-gutter);
}


/* doctolib */
.doctolib{
    display: flex;
    align-items: flex-end;
}
.doctolib picture{
    max-height: 1.4em;
    max-width: 1.4em;
    margin-right: 5px;
}


@media screen and (max-width: 1350px) {

    .container,
    .container-narrow {
        /*padding-left: var(--container-padding);*/
        /*padding-right: var(--container-padding);*/
        min-width: auto;
        width: 90%;
    }
}