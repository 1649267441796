
a,
.btn {
    text-decoration: underline;
}
a:hover,
.btn:hover {
    opacity: 0.5;
}


/* links */

.link {
    display: inline-block;
    white-space: nowrap;
    color: var(--secondary);
    text-transform: uppercase;
    font-size: calc(10px + 1 * (100vw - 320px) / 1080);
    font-weight: 700;
    letter-spacing: 1px;
}
.link:after {
    content: '';
    height: 1px;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    bottom: -2px;
    background-color: var(--secondary);
    transform: scaleX(0);
    transform-origin: left;
    transition: .3s ease-in-out;
}
.link:hover:after {
    transform: scaleX(1);
}