/* cookies */
.cookies-message {
    overflow: hidden;
    background-color: var(--light);
    color: var(--primary);
    box-shadow: 0 15px 20px rgba(0, 0, 0, .3);
    border-radius: 3px;
    transition: transform .6s, opacity .6s, visibility .6s;
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
    max-width: calc(99vw - 20px);
    position: fixed;
    bottom: calc(10px + .5vw);
    left: calc(10px + .5vw);
    z-index: 9999;
    width: 420px;
    font-size: 14px;
    line-height: 1.3;
}
.cks .cookies-message {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
}
.cookies-top {
    display: flex;
    align-items: flex-start;
    padding: 30px 20px 20px;
}
.cookies-message p strong {
    display: block;
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 1.3;
    font-family: var(--ff1);
}
.cookies-message a {
    font-weight: bold;
    text-decoration: underline;
}
.cookies-message a:hover {
    opacity: 0.5;
}
.cookies-bottom {
    display: flex;
    font-size: 13px;
    font-weight: bold;
}
.cookies-bottom > * {
    border-top: 1px solid rgba(var(--rgb-primary), .05);
    flex: 1;
    background-color: rgba(var(--rgb-primary), .05);
    padding: 14px 0 15px;
}
.cookies-bottom > *:hover {
    background-color: rgba(var(--rgb-primary), .15);
}
.cookies-bottom > * + * {
    border-left: 1px solid rgba(var(--rgb-primary), .1);
}
.cookies-bottom small {
    font-size: 12px;
    font-weight: normal;
}
.cookies-icon {
    --stroke: var(--primary);
    --stroke-width: 1;
    --fill: var(--light);
    --dim: 85px;
    height: var(--dim);
    width: var(--dim);
    margin-right: 20px;
}
.cookies-icon .icon {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
}
.cookies-icon .icon:first-child {
    animation: cookies 1.5s infinite;
    z-index: 1;
    --fill: var(--light);
}
.cookies-icon .icon:last-child {
    z-index: 0;
}
@keyframes cookies {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    51% {
        opacity: 0;
    }
    99% {
        opacity: 0;

    }
    100% {
        opacity: 1;

    }
}