*,*:after,*:before{position:relative;box-sizing:border-box;}
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video,button,hr,input,textarea{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block;}
a,button{transition:.3s;cursor:pointer;color:inherit;}
button{border:0;padding:0;background:none;display:inline-block;}
a,a:hover,button,button:hover,*:focus,*:active{outline:none;}
body{-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:subpixel-antialiased;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;-webkit-tap-highlight-color:transparent;}
ol,ul{list-style:none;}
blockquote,q{quotes:none;}
blockquote:before,blockquote:after,q:before,q:after{content:'';content:none;}
table{border-collapse:collapse;border-spacing:0;}
strong{font-weight:bold}
img,svg{vertical-align:bottom;height:auto;}
input,textarea{background-color:transparent;resize:none;}
input[type="date"]{cursor:text}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator{color:rgba(0,0,0,0);background:rgba(0,0,0,0);opacity:0;display:none;}
input::-webkit-datetime-edit-day-field:focus, 
input::-webkit-datetime-edit-month-field:focus, 
input::-webkit-datetime-edit-year-field:focus{background-color:var(--dark);color:var(--light);outline:none;}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0;}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button{-webkit-appearance:none;margin:0;}
input[type=number]{-moz-appearance:textfield;}
select{cursor:pointer;border:0;background-color:transparent;-webkit-appearance:none;font-family:inherit;}
select option:hover{background-color:var(--dark);color:var(--light);}
picture{display:block;}
picture img{width:100%;height:100%;}
em,i{font-style:italic;}
.sf-toolbar{display:none!important;}
[data-link]{cursor:pointer;}
html.wait *{cursor:wait!important}

/* scrollbar styling */
*{scrollbar-color:var(--dark) var(--light);scrollbar-width:thin;}
*::-webkit-scrollbar{width:3px;background-color:var(--dark);}
*::-webkit-scrollbar-thumb{background-color:var(--dark);}
html::-webkit-scrollbar{width:4px;background-color:var(--dark);}
html{scrollbar-width:thin;scroll-behavior:smooth;}

/* selection styling */
*::-moz-selection{background-color:var(--dark);color:var(--light);}
*::selection{background-color:var(--dark);color:var(--light);}

/* selection placeholder */
::-webkit-input-placeholder{color:inherit;opacity:.5}
::-moz-placeholder{color:inherit;opacity:.5}
::-ms-input-placeholder{color:inherit;opacity:.5}



