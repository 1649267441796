:root {

    --primary: rgb(var(--rgb-primary));
    --rgb-primary: 18, 18, 18;
    --secondary: rgb(var(--rgb-secondary));
    --rgb-secondary: 242, 242, 242;
    --light: rgb(var(--rgb-light));
    --rgb-light: 255, 255, 255;
    --dark: rgb(var(--rgb-dark));
    --rgb-dark:  18, 18, 18;

    --section-padding: calc(35px + 25 * (100vw - 320px) / 1080);
    --mid-padding: calc(25px + 20 * (100vw - 320px) / 1080);
    --gap-padding: calc(20px + 15 * (100vw - 320px) / 1080);
    --gutter: calc(15px + 10 * (100vw - 320px) / 1080);
    --min-gutter: calc(10px + 5 * (100vw - 320px) / 1080);
    --container-padding: calc(10px + 75 * (100vw - 320px) / 1080);

    /* header */

    --header-height: calc(60px + 20 * (100vw - 320px) / 1080);

    /* svg */

    --stroke-width: 1.5px;
    --stroke: var(--primary);

    /* transitions */

    --cubic:cubic-bezier(.23, 1, .32, 1);
    --transalte: .5s;

    /* shadows */

    --shadow-section: 1px 1px 12px -3px rgba(0, 0, 0, .05);

    /* font family */

    --ff1: "oswald", "gilory", sans-serif;
    --ff2: "eb-garamond", serif;

    /* font size */

    --font-xl: calc(25px + 10 * (100vw - 320px) / 1080);
    --font-l: calc(20px + 7 * (100vw - 320px) / 1080);
    --font-m: calc(16px + 5 * (100vw - 320px) / 1080);
    --font-s: calc(17px + 1 * (100vw - 320px) / 1080);
    --font-xs: calc(15px + 1 * (100vw - 320px) / 1080);

}

/* font-face  */

@font-face {
    font-display: swap;
    font-family: 'Gilroy';
    src: local('Gilroy Bold'), local('Gilroy-Bold'), url('../../fonts/gilroy/Gilroy-Bold.woff2') format('woff2'), url('../../fonts/gilroy/Gilroy-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Gilroy';
    src: local('Gilroy Bold Italic'), local('Gilroy-BoldItalic'), url('../../fonts/gilroy/Gilroy-BoldItalic.woff2') format('woff2'), url('../../fonts/gilroy/Gilroy-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Gilroy';
    src: local('Gilroy Regular'), local('Gilroy-Regular'), url('../../fonts/gilroy/Gilroy-Regular.woff2') format('woff2'), url('../../fonts/gilroy/Gilroy-Regular.eot?#iefix') format('embedded-opentype'), url('../../fonts/gilroy/Gilroy-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Gilroy';
    src: local('Gilroy Regular Italic'), local('Gilroy-RegularItalic'), url('../../fonts/gilroy/Gilroy-RegularItalic.woff2') format('woff2'), url('../../fonts/gilroy/Gilroy-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
