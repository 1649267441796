@import "mods/reset.css";
@import "mods/var.css";
@import "mods/components.css";

html, body {
    position: relative;
    overflow-x: hidden;
    line-height: 1.2;
    background: var(--light);
    color: var(--primary);
    width: 100%;
    font-family: var(--ff2);
    font-size: var(--font-s);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h2,
h3,
h4,
.nav-list{
    font-family: var(--ff1);
}

main{
    min-height: 100vh;
}

section{
    margin-bottom: calc(var(--header-height)*0.8);
}
